import request from '@/utils/request';

export function getCheckCookBookType(){
  return request({
    url: '/cook_book_type/check_cook_book_type',
    method: 'get',
  })
}

export function getCookBookTypeList(data) {
    return request({
        url: '/cook_book_type/list',
        method: 'post',
        data: data
    });
}

export function getCookBookTypeInfo(id) {
    return request({
        url: '/cook_book_type/info/' + id,
        method: 'get',
    });
}

export function addCookBookType(data) {
    return request({
        url: '/cook_book_type/add',
        method: 'post',
        data: data
    });
}

export function editCookBookType(id, data){
    return request({
        url: '/cook_book_type/edit/' + id,
        method: 'put',
        data: data
    });
}

export function delCookBookType(data) {
    return request({
        url: '/cook_book_type/del',
        method: 'post',
        data: data
    });
}
