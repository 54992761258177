<template>
    <el-dialog :visible.sync="formDialog" title="编辑" :close-on-click-modal="false" @close="closeForm">
        <el-form ref="dataForm" :model="dataForm" :rules="ruleForm" label-width="100px" class="data-form">
            <el-form-item label="分类名" prop="name">
                <el-input v-model="dataForm.name" placeholder="分类名"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="submitForm()">提交</el-button>
                <el-button @click="resetForm()">重置</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
</template>

<script>
import { getCookBookTypeInfo, editCookBookType } from '@/utils/servers/cookBookType.js';
export default {
    props: ['editFormDialog', 'editFormId'],
    data() {
        return {
            formDialog: this.editFormDialog,
            dataForm: {
                id: 0,
                name: ''
            },
            ruleForm: {
                name: [{ required: true, trigger: 'blur' }]
            },
            checkCookBookType: []
        };
    },
    created() {
        this.getCookBookTypeInfo();
    },
    methods: {
        getCookBookTypeInfo() {
            getCookBookTypeInfo(this.editFormId).then(res => {
                var data = res;
                if (data.code == 1) {
                    this.dataForm.id = data.data.id;
                    this.dataForm.name = data.data.name;
                } else {
                    this.$message.error(data.msg);
                }
            });
        },
        submitForm() {
            editCookBookType(this.dataForm.id, this.dataForm).then(res => {
                var data = res;
                if (data.code == 1) {
                    this.$message.success(data.msg);
                    this.closeForm();
                    this.$emit('getCookBookTypeList');
                } else {
                    this.$message.error(data.msg);
                }
            });
        },
        resetForm() {
            this.$refs.dataForm.resetFields();
        },
        closeForm() {
            this.$emit('closeForm', 'edit');
        }
    }
};
</script>

<style lang="less" scoped>
.data-form {
    width: 400px;
}
</style>
